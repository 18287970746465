@import '../../variables.scss';

.about-section {
  background-image: url('https://i.postimg.cc/9QmrGmPC/white.png');
  padding-bottom: 10%;
  color: #3f3838;
  padding-top: 50vh;
}

.about-block {
  margin: 5% 0;
}

.about-title {
  position: relative;
  background: $title-gradient-dark;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}

.about-text {
  font-size: 18px;
  font-weight: 300;
  padding: 8px 0;
  opacity: 0.75;
}

.about-text-header {
  text-transform: uppercase;
  padding-top: 5%;
  font-size: 24px;
  font-weight: 400;
  color: #afafaf;
}

.timeline-container {
  display: flex;
  flex-direction: column;
  margin-top: 5%;
}

.timeline-container span {
  width: 1.5px;
  background-image: $separator-gradient;
  opacity: 0.2;
}

.timeline-container span.vertical-top {
  background-image: $separator-gradient-top;
}

.timeline-container span.vertical-middle {
  background: rgba(0, 0, 0, 1);
}

.timeline-container span.vertical-bottom {
  background-image: $separator-gradient-bottom;
}

.timeline-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.collection-list > div.right {
  padding-left: 10%;
  text-align: left;
}

.collection-list > div.left {
  padding-right: 10%;
  text-align: right;
}

.collection-year.center {
  font-size: 20px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 18px;
}

.collection-year.right {
  padding-left: 10%;
  text-align: left;
  margin: auto;
  width: 50%;
}

.collection-year.left {
  padding-right: 10%;
  text-align: right;
  margin: auto;
  width: 50%;
}

.collection-list span {
  display: block;
  width: 100%;
  height: 1px;
}

.collection-list {
  width: 50%;
}

.collection-list span.right {
  background-image: $separator-gradient-right;
}

.collection-list span.left {
  background-image: $separator-gradient-left;
}

.collection-name {
  font-size: 18px;
  font-style: italic;
}

.collection-location {
  font-size: 16px;
  font-weight: 300;
  opacity: 0.6;
}

/* MOBILE PORTRAIT */
@media (max-width: 414px) {
  .timeline-container {
    padding: 0 8px;
  }

  .about-text-header {
    font-size: 20px;
  }

  .about-text {
    font-size: 14px;
    padding: 8px;
  }

  .collection-name {
    font-size: 16px;
  }

  .collection-location {
    font-size: 14px;
  }

  .collection-year {
    font-size: 16px;
  }
}

/* MOBILE LANDSCAPE */
@media (min-width: 568px) and (max-width: 825px) {
  .timeline-container {
    padding: 0 8px;
  }

  .about-text-header {
    font-size: 20px;
  }

  .about-text {
    font-size: 16px;
    padding: 8px;
  }

  .collection-name {
    font-size: 16px;
  }

  .collection-location {
    font-size: 14px;
  }

  .collection-year {
    font-size: 16px;
  }
}

/* TABLET LANDSCAPE */
@media (min-width: 1024px) and (max-width: 1112px) {
  .about-title {
    font-size: 120px;
  }
}

/* ALL LANDSCAPE */
@media (max-width: 1200px) {
}
