@import '../../variables.scss';

.collections-section {
  background-image: url('https://i.postimg.cc/7668qcy3/black.png');
  color: #8a8a8a;
}

.collections-title {
  word-wrap: break-word;
  background: $title-gradient-light;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}

.collection-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2.5%;
  padding-top: 2.5%;
  border-bottom: 1px solid #242424;
}

.collection-year {
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 10%;
}

.collection-title {
  font-size: 22px;
  font-style: italic;
  font-weight: 300;
}

.collection-desc {
  font-size: 16px;
  font-weight: 100;
  opacity: 0.7;
  line-height: 30px;
  margin-bottom: 10%;
  letter-spacing: 0.5px;
}

.collection-carousel,
.collection-info {
  width: 30%;
}

.collection-info {
  margin-right: 5%;
  text-align: left;
}

.collection-painting {
  object-fit: contain;
  height: 100%;
}

.collection-painting-container {
  max-height: 50vh;
  height: 100%;
}

.explore-button button {
  width: 60%;
  min-width: 310px;
}

@media (max-width: 825px) {
  .collection-info {
    order: 2;
  }
  .collection-container {
    flex-direction: column;
    padding-bottom: 10%;
  }
  .collection-carousel {
    order: 1;
  }
}

/* MOBILE PORTRAIT & LANDSCAPE */
@media (max-width: 825px) {
  .collections-title {
    font-size: 55px;
    -webkit-text-fill-color: rgba(107, 107, 107, 0.5);
  }
  .explore-button button {
    width: 85vw;
    min-width: 275px;
  }
  .collection-info {
    width: 80%;
  }
  .collection-desc {
    font-size: 14px;
  }
  .collection-year {
    font-size: 18px;
  }
  .collection-title {
    font-size: 20px;
  }
  .collection-carousel {
    width: 80%;
    padding-bottom: 5%;
  }
  .collection-carousel ul {
    padding-inline-start: 0;
  }
  .collection-painting-container {
    max-height: 60vh;
  }
}

/* MOBILE LANDSCAPE */
@media (min-width: 568px) and (max-width: 825px) {
  .collection-info {
    width: 70%;
  }
  .explore-button button {
    width: 70vw;
    min-width: 275px;
  }
  .collection-painting-container {
    max-height: 90vh;
  }
}

/* TABLET PORTRAIT */
@media (min-width: 768px) and (max-width: 824px) and (min-height: 1000px) {
  .collection-painting-container {
    max-height: 40vh;
  }
}

/* TABLET LANDSCAPE */
@media (min-width: 1024px) and (max-width: 1400px) {
  .collection-info,
  .collection-carousel {
    width: 40%;
  }
}

/* CAROUSEL STYLES */
.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  -webkit-transition: all 0.5s ease-in;
  -moz-transition: all 0.5s ease-in;
  -ms-transition: all 0.5s ease-in;
  -o-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
  opacity: 0.4;
  filter: alpha(opacity=40);
  position: absolute;
  z-index: 2;
  top: 20px;
  background: none;
  border: 0;
  font-size: 32px;
  cursor: pointer;
}
.carousel .control-arrow:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  margin: 0 5px;
  display: inline-block;
  border-top: 8px solid rgba(255, 255, 255, 0);
  border-bottom: 8px solid rgba(255, 255, 255, 0);
  content: '';
}
.carousel .control-disabled.control-arrow {
  opacity: 0;
  filter: alpha(opacity=0);
  cursor: inherit;
}
/* display: none; } */
.carousel .control-prev.control-arrow {
  left: 0;
}
.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #fff;
}
.carousel .control-next.control-arrow {
  right: 0;
}
.carousel .control-next.control-arrow:before {
  border-left: 8px solid #fff;
}

.carousel {
  display: hidden;
  position: relative;
  width: 100%;
}
.carousel * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.carousel img {
  width: 100%;
  display: inline-block;
  pointer-events: none;
}
.carousel .carousel {
  position: relative;
}
.carousel .control-arrow {
  outline: 0;
  border: 0;
  background: none;
  top: 50%;
  margin-top: -13px;
  font-size: 18px;
}
.carousel .thumbs-wrapper {
  display: none;
  margin: 20px;
  overflow: hidden;
}
.carousel .thumbs {
  -webkit-transition: all 0.15s ease-in;
  -moz-transition: all 0.15s ease-in;
  -ms-transition: all 0.15s ease-in;
  -o-transition: all 0.15s ease-in;
  transition: all 0.15s ease-in;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  position: relative;
  list-style: none;
  white-space: nowrap;
}
.carousel .thumb {
  -webkit-transition: border 0.15s ease-in;
  -moz-transition: border 0.15s ease-in;
  -ms-transition: border 0.15s ease-in;
  -o-transition: border 0.15s ease-in;
  transition: border 0.15s ease-in;
  display: inline-block;
  width: 80px;
  margin-right: 6px;
  white-space: nowrap;
  overflow: hidden;
  border: 3px solid #fff;
  padding: 2px;
}
.carousel .thumb:focus {
  border: 3px solid #ccc;
  outline: none;
}
.carousel .thumb.selected,
.carousel .thumb:hover {
  border: 3px solid #333;
}
.carousel .thumb img {
  vertical-align: top;
}
.carousel.carousel-slider {
  position: relative;
  margin: 0;
  overflow: hidden;
}
.carousel.carousel-slider .control-arrow {
  top: 0;
  color: #fff;
  font-size: 26px;
  bottom: 0;
  margin-top: 0;
  padding: 5px;
}
.carousel .slider-wrapper {
  overflow: hidden;
  margin: auto;
  width: 100%;
  -webkit-transition: height 0.15s ease-in;
  -moz-transition: height 0.15s ease-in;
  -ms-transition: height 0.15s ease-in;
  -o-transition: height 0.15s ease-in;
  transition: height 0.15s ease-in;
}
.carousel .slider-wrapper.axis-horizontal .slider {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}
.carousel .slider-wrapper.axis-horizontal .slider .slide {
  flex-direction: column;
  flex-flow: column;
}
.carousel .slider-wrapper.axis-vertical {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}
.carousel .slider-wrapper.axis-vertical .slider {
  -webkit-flex-direction: column;
  flex-direction: column;
}
.carousel .slider {
  margin: 0;
  padding: 0;
  position: relative;
  list-style: none;
  width: 100%;
}
.carousel .slider.animated {
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
.carousel .slide {
  min-width: 100%;
  margin: 0;
  position: relative;
  text-align: center;
}
.carousel .slide img {
  width: 100%;
  vertical-align: top;
  border: 0;
}
.carousel .slide iframe {
  display: inline-block;
  width: calc(100% - 80px);
  margin: 0 40px 40px;
  border: 0;
}
.carousel .slide .legend {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: absolute;
  bottom: 40px;
  left: 50%;
  margin-left: -45%;
  width: 90%;
  color: #8a8a8a;
  background: rgba(0, 0, 0, 0.6);
  font-style: italic;
  padding: 10px;
  font-size: 14px;
  letter-spacing: 0.5px;
  text-align: center;
  opacity: 0.25;
  -webkit-transition: opacity 0.35s ease-in-out;
  -moz-transition: opacity 0.35s ease-in-out;
  -ms-transition: opacity 0.35s ease-in-out;
  -o-transition: opacity 0.35s ease-in-out;
  transition: opacity 0.35s ease-in-out;
}
.carousel .control-dots {
  position: absolute;
  bottom: 0;
  margin: 10px 0;
  text-align: center;
  width: 100%;
}
@media (min-width: 960px) {
  .carousel .control-dots {
    bottom: 0;
  }
}
.carousel .control-dots .dot {
  -webkit-transition: opacity 0.25s ease-in;
  -moz-transition: opacity 0.25s ease-in;
  -ms-transition: opacity 0.25s ease-in;
  -o-transition: opacity 0.25s ease-in;
  transition: opacity 0.25s ease-in;
  opacity: 0.3;
  filter: alpha(opacity=30);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
  background: #fff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px;
}
.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.carousel .carousel-status {
  display: none;
}
.carousel:hover .slide .legend {
  opacity: 1;
}

@media only screen and (max-width: 1023px) {
  .carousel .slide .legend {
    opacity: 0.8;
  }
}
