@import '../../variables.scss';

.landing-section {
  justify-content: center;
}

.landing-video {
  width: 100%;
  text-align: center;
  position: absolute;
  z-index: 1000;
  top: 100%;
}

.landing-video-frame {
  width: 60vw;
  height: 55vh;
  max-width: 1000px;
  min-width: 350px;
  border: none;
}

.landing-title {
  position: relative;
  font-size: 250px;
  background: $title-gradient-light;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  opacity: 0.8;
}

.landing-logo {
  position: relative;
  bottom: 300px;
  opacity: 0.7;
}

.scroll-icon {
  position: relative;
  bottom: 0px;
  opacity: 0.5;
}

.scroll-icon img {
  width: 90px;
}

/* MOBILE PORTRAIT */
@media (max-width: 414px) {
  .landing-title {
    font-size: 90px;
  }

  .landing-logo {
    bottom: 100px;
  }

  .landing-logo img {
    width: 50%;
  }

  .scroll-icon {
    bottom: -50px;
    opacity: 0.6;
  }

  .scroll-icon img {
    width: 25%;
  }
}

/* MOBILE LANDSCAPE */
@media (min-width: 568px) and (max-width: 825px) {
  .landing-title {
    font-size: 140px;
  }

  .landing-logo {
    bottom: 170px;
  }

  .landing-logo img {
    width: 40%;
  }

  .scroll-icon {
    bottom: 130px;
    opacity: 0.6;
  }
}

/* TABLET PORTRAIT */
@media (min-width: 768px) and (max-width: 834px) {
  .landing-title {
    font-size: 200px;
  }

  .landing-logo {
    bottom: 240px;
  }

  .scroll-icon {
    bottom: 0;
  }

  .scroll-icon img {
    width: 25%;
  }
}

/* SMALL SCREEN */
@media (max-width: 1024px) {
  .landing-video-frame {
    width: 100vw;
  }
}

@media (max-width: 1600px) {
  .scroll-icon {
    bottom: 175px;
  }
}
