@import '../../variables.scss';

.ReactModal__Overlay {
  background: rgba(0, 0, 0, 0.15) !important;
  z-index: 100 !important;
}

.ReactModal__Content {
  border: none !important;
  padding: 20px !important;
  background: transparent !important;
  margin: auto;
  max-width: 90%;
  max-height: 90%;
}

html:has(body.ReactModal__Open-Body) {
  overflow: hidden;
  body.ReactModal__Open-Body {
    overflow: hidden;
    div#root {
      overflow: hidden;
    }
  }
}

@media (max-width: 1023px) {
  .ReactModal__Overlay {
    display: none;
  }
}
