@import url('https://fonts.googleapis.com/css?family=Raleway:100,200,400|Roboto:300,300i,400&display=swap');
@import './variables.scss';

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body,
#root {
  min-width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
}

body {
  margin: 0;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body:before,
body:after {
  display: none;
}

h1 {
  font-size: 150px;
  font-family: 'Raleway', sans-serif;
  font-weight: 100;
}

.section {
  overflow-y: scroll;
  overflow-x: hidden;
  min-height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  animation: image-load 2.5s;
}

.header {
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
}

.header-overlay {
  width: 100%;
  height: 70px;
  z-index: 50;
  position: fixed;
  background: rgba(255, 255, 255, 0);
  transition: all 300ms ease-in-out;
  display: none;
}

.header-overlay.header-transparent {
  background: rgba(255, 255, 255, 0);
}

.header-mobile {
  display: none;
}

.header-desktop {
  z-index: 50;
  position: fixed;
  display: flex;
  width: 100%;
  padding: 8px;
  background: rgba(255, 255, 255, 0);
  transition: all 300ms ease-in-out;
}

.menu-item {
  cursor: pointer;
}

.header-desktop.header-background {
  width: auto;
  height: 100%;
}

.header-desktop.header-transparent {
  background: rgba(255, 255, 255, 0);
}

.header-desktop nav {
  width: 100%;
}

.header-desktop.header-background nav .vertical-divider {
  border-right: 1px solid #8a8a8a;
  height: 100%;
}

.header-desktop nav ul {
  display: flex;
  width: 100%;
  justify-content: space-around;
  transition: all 500ms ease-in-out;
}

.header-desktop.header-background nav ul {
  display: block;
  width: auto;
  height: 100%;
  padding-inline-start: 0;
  padding: 16px;
}

.header-desktop nav ul li {
  list-style: none;
  padding: 0 16px;
}

.header-desktop.header-background nav ul li {
  padding: 16px 0;
}

.header-desktop nav ul.white-text {
  border-left: 1px solid #3f3838;
}

.header-desktop nav ul.white-text li a {
  color: #8a8a8a;
}

.header-desktop nav ul.white-text li a:link {
  color: #8a8a8a;
}

.header-desktop nav ul.white-text li a:active {
  color: #8a8a8a;
}

.header-desktop nav ul.white-text li a:visited {
  color: #8a8a8a;
}

.header-desktop nav ul.white-text li a:hover {
  color: white;
}

.header-desktop nav ul.grey-text {
  border-left: 1px solid #959494;
}

.header-desktop nav ul.grey-text li a {
  color: #cbcbcb;
}

.header-desktop nav ul.grey-text li a:link {
  color: #cbcbcb;
}

.header-desktop nav ul.grey-text li a:active {
  color: #cbcbcb;
}

.header-desktop nav ul.grey-text li a:visited {
  color: #cbcbcb;
}

.header-desktop nav ul.white-text li a:hover {
  color: white;
}

.header-desktop nav ul.black-text {
  border-left: 1px solid #bebebe;
}

.header-desktop nav ul.black-text li a {
  color: #373a47;
}

.header-desktop nav ul.black-text li a:link {
  color: #373a47;
}

.header-desktop nav ul.black-text li a:active {
  color: #373a47;
}

.header-desktop nav ul.black-text li a:visited {
  color: #373a47;
}

.header-desktop nav ul.black-text li a:hover {
  color: black;
}

.header-desktop nav ul li a {
  text-decoration: none;
  font-size: 14px;
  font-weight: 100;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  text-shadow: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.header-desktop nav ul li a span {
  text-transform: lowercase;
}

button.button {
  background: $button-gradient-light;
  outline: 0;
  border: none;
  padding: 16px;
  cursor: pointer;
  box-shadow: none;
  font-size: 14px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  color: #8a8a8a;
  font-family: 'Roboto', sans-serif;
}

button.button:hover {
  background: $button-gradient-light-hover;
  color: #a9a9a9;
}

button.button a {
  width: 100%;
  height: 100%;
  font-size: 14px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  color: #848484;
  font-family: 'Roboto', sans-serif;
  transition: all 0.1s ease-in-out;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
}

@keyframes image-load {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 1400px) {
  .header-desktop {
    display: none;
  }
  .header-mobile {
    display: block;
  }
  .header-overlay {
    display: block;
    height: 48px;
  }
  .header-mobile.header-background {
    width: 100%;
    height: 100%;
  }
  .gallery-title,
  .about-title,
  .collections-title,
  .contact-title,
  .artworks-title {
    padding-top: 30px;
  }
}

/* MOBILE PORTRAIT */
@media (max-width: 414px) {
  h1 {
    font-size: 65px;
  }
}

/* MOBILE LANDSCAPE */
@media (min-width: 568px) and (max-width: 812px) {
  h1 {
    font-size: 80px;
  }
}

/* TABLET PORTRAIT */
@media (min-width: 768px) and (max-width: 834px) {
  .section {
    top: 0;
  }

  h1 {
    font-size: 100px;
  }
}

/* TABLET LANDSCAPE */
@media (min-width: 1024px) and (max-width: 1112px) {
  .section {
    top: 0;
  }

  h1 {
    font-size: 120px;
  }
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 22px;
  height: 18px;
  left: 16px;
  top: 16px;
}

.bm-burger-button button {
  outline: 0;
}

.bm-burger-bars {
  opacity: 0.4 !important;
  height: 1px !important;
}

/* Color/shape of burger icon bars */
.header-mobile.white .bm-burger-bars {
  background: #8a8a8a;
}

.header-mobile.black .bm-burger-bars {
  background: #373a47;
}

.header-mobile.grey .bm-burger-bars {
  background: #f3f3f3;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 30px;
  width: 30px;
}

.bm-cross-button button {
  outline: 0;
}

/* Color/shape of close button cross */
.bm-cross {
  background: $icon-gradient;
  opacity: 0.4;
  width: 1px !important;
  height: 24px !important;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: rgba(25, 24, 26, 0.8);
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list a {
  color: #8a8a8a;
  text-decoration: none;
  font-size: 14px;
  font-weight: 100;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  padding: 16px 0;
  outline: 0;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

@-webkit-keyframes glow {
  from {
    text-shadow: 0 2px 4px rgba(234, 234, 234, 0.2);
  }
  to {
    text-shadow: 0 4px 8px rgba(234, 234, 234, 0.2);
  }
}
